import React from "react";


export default class Analytics {

  static canUseGA() {
    return process.env.NODE_ENV === 'production';
  }

  static setup() {
    if (!Analytics.canUseGA()) {
      return
    }
    // Set default dimensions to send with every pageview and event
    window.ga('set', 'dimension1', `${Analytics.isPWA()}`);
  };

  static script() {
    if (!Analytics.canUseGA()) {
      return ''
    }

    return (
      <script dangerouslySetInnerHTML={{__html:
        `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', 'UA-146654160-1', 'auto');`
      }} />
    )
  }

  static pageview(url) {
    if (!Analytics.canUseGA()) {
      return
    }
    window.ga('send', 'pageview', url);
  }

  static event({category, action, label}) {
    if (!Analytics.canUseGA()) {
      return
    }

    window.ga('send', 'event', category, action, label);
  }

  static isPWA() {
    return (
      window.navigator.standalone === true ||                             // iOS
      window.matchMedia('(display-mode: standalone)').matches      // Android
    );
  }
}